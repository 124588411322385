export enum ReservationType {
  ConferenceRoom = 'ConferenceRoom',
  DailyDesk = 'DailyDesk',
  PrivateOffice = 'PrivateOffice',
  PrivateAccessArea = 'PrivateAccessArea'
}

export interface ReservationItemData {
  uuid: string;
  start: string;
  finish: string;
  user_uuid: string;
  reservable_type: ReservationType;
  reservable_name: string;
  user_name: string;
  checked_in: boolean;
}
