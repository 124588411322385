import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import bemblock from 'bem-cn';
import { LogoutButton } from '../logout-button/logout-button';
import './profile.scss';

const b = bemblock('profile');
export function Profile() {
  const { user } = useAuth0();

  if (!user) {
    return <></>;
  }

  return (
    <div className={b()}>
      <img src={user.picture} alt={user.name} width="32" className={b('avatar')} />
      <span className={b('user-name')}>Welcome, {user.name}</span>
      <LogoutButton />
    </div>
  );
}
