import { useAuth0 } from '@auth0/auth0-react';

export function useLogout() {
  const { logout: doLogout } = useAuth0();

  const logout = (options?) => {
    // add any necessary cleanup code here

    doLogout(options);
  };

  return { logout };
}
