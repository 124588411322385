import axios from 'axios';
import { buildDateWithTimezone } from '../../features/reservation/reservation.helpers';
import { CHECK_IN_V1, RESERVATIONS_V1 } from './constants';

export const getReservationsList = async (itemsPerPage, page, locationUuid, timeZone, selectedDate, authToken, emitError) => {
  if (!authToken) return Promise.resolve([]);

  const startGte = buildDateWithTimezone('00:00', selectedDate, timeZone).toISO();
  const startLte = buildDateWithTimezone('23:59', selectedDate, timeZone).toISO();

  try {
    const response = await axios.get(RESERVATIONS_V1, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        location_uuid: locationUuid,
        start_gte: startGte,
        start_lte: startLte,
        page: page,
        size: itemsPerPage
      },
    });

    return response?.data?.data;
  } catch (e) {
    emitError();
  }
};

export const postReservationChanges = async (
  reservationChanges,
  authToken,
  emitError,
) => {
  if (!authToken) return;

  try {
    const response = await axios.post(CHECK_IN_V1,
      reservationChanges,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        },
      });

    return response?.data?.data;
  } catch (e) {
    emitError();
  }
}
