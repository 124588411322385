import React from 'react';
import { block } from 'bem-cn';
import { ReactComponent as WeWorkLogoSVG } from '../../assets/icons/wework-logo.svg';
import { Profile } from '../profile/profile';
import './header.scss';

export function Header() {
  const bem = block('header');

  return (
    <div className={bem()}>
      <WeWorkLogoSVG className={`${bem('logo')}`} />
      <Profile />
    </div>
  );
}
