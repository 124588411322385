import React, { ReactElement, useEffect, useState } from 'react';
import block from 'bem-cn';
import { ReactComponent as SearchSVG } from '../../assets/icons/no-item-search.svg';
import { ReactComponent as CircleRedSVG } from '../../assets/icons/x-circle-red.svg';
import { ReactComponent as SuccessCheckSVG } from '../../assets/icons/success-check-green.svg';
import './toast-message.scss';

export enum ToastMessageType {
  Error = 'error',
  Success = 'success',
  Info = 'info',
  FutureError = 'futureError',
}

export interface ToastMessageProps {
  type: ToastMessageType;
  autoClose?: boolean;
  message?: string;
  hideToast?: () => void;
}

const b = block('toast-message');

const TIMEOUT_DURATION = 4000;

export const ToastMessage = ({
  type = ToastMessageType.Info,
  autoClose = false,
  message,
  hideToast,
}: ToastMessageProps): ReactElement => {
  const [showMessage, setShowMessage] = useState(true);

  const toastMessages = {
    success: {
      icon: <SuccessCheckSVG />,
      title: 'Completed',
      description: 'Reservation(s) successfully updated',
    },
    error: {
      icon: <CircleRedSVG width="32" height="32" />,
      title: 'Something went wrong',
      description: 'Please wait a moment and then try again.',
    },
    info: {
      icon: <SearchSVG />,
      title: 'No Results',
      description: 'Try adjusting your search filters to find what you are looking for.',
    },
    futureError: {
      icon: <CircleRedSVG width="32" height="32" />,
      title: 'Cannot check in a future date',
      description: 'Check in is disabled for future dates',
    },
  }[type];

  const { icon, title, description } = toastMessages;

  useEffect(() => {
    const autoCloseTimeout = setTimeout(() => {
      hideToast && hideToast();
      // todo: after adding react query implementation - call refresh get reservations
      // and re-enable hiding of a toast
      // setShowMessage(false);
      window.location.reload();
    }, TIMEOUT_DURATION);

    return () => {
      clearTimeout(autoCloseTimeout);
    };
  }, []);

  if (!showMessage && autoClose) {
    return <></>;
  }

  return (
    <div className={b()}>
      <span className={b('icon')}>{icon}</span>
      <span className={b('title')}>{title}</span>
      <span className={b('description')}>{message ? message : description}</span>
    </div>
  );
};
