import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Header } from './components/header/header';
import { Footer } from './components/footer/footer';
import { ReservationList } from './features/reservation/reservation-list';
import './App.scss';

console.info('env:', { node: process.env.NODE_ENV, app: process.env.REACT_APP_ENVIRONMENT });

export function App() {
  return (
    <div className="app-container">
      <Header />
      <ReservationList />
      <Footer />
    </div>
  );
}
