import bemblock from 'bem-cn';
import { Placeholder, Checkbox } from 'semantic-ui-react';
import { formatDateInZone, formatStartToEnd, getTimeZone } from './reservation.helpers';
import './reservation-item.scss';
import { ReservationType } from '../../types/types';

export interface ReservationItemProps {
  userName: string;
  reservableType: ReservationType;
  reservableName: string;
  start: string;
  finish: string;
  checked: boolean;
  onClick: () => void;
}

const b = bemblock('reservation-item');

export function ReservationItem(props: ReservationItemProps) {
  const { userName, reservableType, reservableName, start, finish, checked, onClick } = props;
  const locationTimeZone: string = getTimeZone();

  let reservableDisplay;
  switch (reservableType) {
    case ReservationType.ConferenceRoom:
      reservableDisplay = 'Room ' + reservableName;
      break;
    case ReservationType.PrivateOffice:
      reservableDisplay = 'Office ' + reservableName;
      break;
    case ReservationType.DailyDesk:
    case ReservationType.PrivateAccessArea:
    default:
      reservableDisplay = reservableType;
  }

  return (
    <div className={b()}>
      <div className={b('wrapper')}>
        <Checkbox className={b('custom-checkbox').toString()} checked={checked} onClick={onClick} />
      </div>
      <div className={b('info')}>{userName}</div>
      <div className={b('info')}>{reservableDisplay}</div>
      <div className={b('info')}>{formatDateInZone(start, locationTimeZone)}</div>
      <div className={b('info')}>{formatStartToEnd(start, finish, 'en-US', locationTimeZone)}</div>
    </div>
  );
}

const LoadingReservationItem = () => (
  <div className={b({ loading: true })}>
    <div className={b('wrapper')}>
      <Placeholder fluid>
        <Placeholder.Image square className={b('image').toString()} />
      </Placeholder>
    </div>
    <div className={b('info')}>
      <Placeholder fluid>
        <Placeholder.Paragraph>
          <Placeholder.Line length="full" />
        </Placeholder.Paragraph>
      </Placeholder>
    </div>
    <div className={b('info')}>
      <Placeholder fluid>
        <Placeholder.Paragraph>
          <Placeholder.Line length="full" />
        </Placeholder.Paragraph>
      </Placeholder>
    </div>
    <div className={b('info')}>
      <Placeholder fluid>
        <Placeholder.Paragraph>
          <Placeholder.Line length="full" />
        </Placeholder.Paragraph>
      </Placeholder>
    </div>
    <div className={b('info')}>
      <Placeholder fluid>
        <Placeholder.Paragraph>
          <Placeholder.Line length="full" />
        </Placeholder.Paragraph>
      </Placeholder>
    </div>
  </div>
);

const Loading = () => {
  const loadingReservationItems: JSX.Element[] = [];

  for (let i = 0; i < 5; i++) {
    loadingReservationItems.push(<LoadingReservationItem key={i} />);
  }

  return <div>{loadingReservationItems}</div>;
};

ReservationItem.Loading = Loading;
