import bemblock from 'bem-cn';
import { formatDateInZone, formatStartToEnd, getTimeZone } from '../../features/reservation/reservation.helpers';
import { ReservationItemData } from '../../types/types';
import './reservation-line.scss';

const b = bemblock('reservation-line');

interface ReservationLineProps {
  reservation: ReservationItemData;
}

export const ReservationLine = (props: ReservationLineProps) => {
  const { user_name, start, finish, checked_in } = props.reservation;
  const locationTimeZone: string = getTimeZone();

  return (
    <div className={b()}>
      <div className={b('status', { checked_in })}>
        <label className={b('label', { checked_in })}>{`${checked_in ? 'Check In' : 'Undo Check In'}`}</label>
      </div>
      <div className={b('name')}>{user_name}</div>
      <div className={b('date')}>{formatDateInZone(start, locationTimeZone)}</div>
      <div className={b('info')}>{formatStartToEnd(start, finish, 'en-US', locationTimeZone)}</div>
    </div>
  );
};
