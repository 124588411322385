import bemblock from 'bem-cn';
import { ReactComponent as Search } from '../../assets/icons/search.svg';
import './search-filter.scss';

const b = bemblock('search-filter');

interface SearchFilterProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function SearchFilter({ onChange }: SearchFilterProps) {
  return (
    <div className={b()}>
      <input
        className={b('input')}
        type="search"
        name="search-filter"
        placeholder="Search By Name"
        onChange={onChange}
      />
      <Search className={b('icon')} />
    </div>
  );
}
