import React, { ReactNode, CSSProperties } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { ToastMessageType, ToastMessage } from './toast-message/toast-message';
import { SpinnerOverlay } from './spinner-overlay/spinner-overlay';

export interface Auth0WrapperProps {
  children: ReactNode;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
} as CSSProperties;

export const Wrapper = ({ children }: Auth0WrapperProps) => {
  const { error } = useAuth0();

  if (error) {
    return (
      <div style={style}>
        {/* TODO: Rework on error handling, show custom error message, more related to our app, not the actual error */}
        <ToastMessage type={ToastMessageType.Error} message={error.message} />
      </div>
    );
  }

  return <>{children}</>;
};

export const Auth0Wrapper = withAuthenticationRequired(Wrapper, {
  onRedirecting: () => <SpinnerOverlay loading={true} />,
});
