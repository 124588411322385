import bemblock from 'bem-cn';
import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { stringPluralize } from '../../features/reservation/reservation.helpers';
import { ReservationItemData } from '../../types/types';
import { ReservationLine } from './reservation-line';
import './confirmation-modal.scss';
import { useLogout } from '../logout';
import { useAuth0 } from '@auth0/auth0-react';
import { postReservationChanges } from '../../lib/api/api';

const b = bemblock('confirmation-modal');

interface ConfirmationModalProps {
  reservations: ReservationItemData[];
  showError: (boolean) => void;
  showSuccess: (boolean) => void;
  clearModifiedReservations: () => void;
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { reservations , showError, showSuccess, clearModifiedReservations} = props;
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const { logout } = useLogout();
  const { user, getAccessTokenSilently } = useAuth0();

  const transformedReservations = reservations.map((reservation) => {
    return {
      reservation_uuid: reservation.uuid,
      checked_in: reservation.checked_in,
      checked_in_by: user?.email,
    };
  });

  const updateReservations = () => {
    getAccessTokenSilently().then((authToken) => {
      try {
        postReservationChanges(transformedReservations, authToken, showError)
          .then(() => {
            clearModifiedReservations();
            showSuccess(true);
          });
      } catch (e) {
        showError(true);
      }
    });
  }

  return (
    <div className={b()}>
      {!!reservations?.length ? (
        <span>Update {stringPluralize(reservations.length, 'selected reservation')}</span>
      ) : (
        <span>Select reservations to check in</span>
      )}
      <Modal
        onClose={() => setIsConfirmationModalOpen(false)}
        onOpen={() => setIsConfirmationModalOpen(true)}
        open={isConfirmationModalOpen}
        trigger={<Button disabled={!reservations?.length}>Check In</Button>}
      >
        <Modal.Header>Please confirm following changes</Modal.Header>
        <Modal.Content>
          {reservations.map((reservation) => {
            return <ReservationLine key={reservation.uuid} reservation={reservation} />;
          })}
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setIsConfirmationModalOpen(false)}>
            Cancel
          </Button>
          <Button color="green" onClick={() => {
          updateReservations();
          setIsConfirmationModalOpen(false);
        }}>
            Submit Changes
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
