import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import bemblock from 'bem-cn';
import './logout-button.scss';

const b = bemblock('logout-button');

export function LogoutButton() {
  const { isAuthenticated, logout } = useAuth0();

  if (!isAuthenticated) {
    return <></>;
  }

  return (
    <button
      type="button"
      className={b()}
      onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
    >
      Log out
    </button>
  );
}
