/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect, ChangeEventHandler, ChangeEvent } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import debounce from 'lodash/debounce';
import bemblock from 'bem-cn';
import { Pagination } from 'semantic-ui-react';
import { SelectSingleEventHandler } from 'react-day-picker';
import { DateTime } from 'luxon';
import { format, parse } from 'date-fns';
import { SearchFilter } from '../../components/search-filter/search-filter';
import { ToastMessageType, ToastMessage } from '../../components/toast-message/toast-message';
import { DateSelector } from '../../components/date-selector/date-selector';
import { ConfirmationModal } from '../../components/confirmation/confirmation-modal';
import { getReservationsList } from '../../lib/api/api';
import { ReservationItemData } from '../../types/types';
import { ReservationItem } from './reservation-item';
import { DEBOUNCE_DURATION } from './reservation.constants';
import { getLocationUUID, getTimeZone, stringPluralize } from './reservation.helpers';
import './reservation-list.scss';

const b = bemblock('reservation-list');
const ITEMS_PER_CALL = 100;

export function ReservationList() {
  const timeZone = getTimeZone();
  const now = new Date();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [reservationsData, setReservationsData] = useState<ReservationItemData[]>([]);
  const [filteredReservations, setFilteredReservations] = useState<ReservationItemData[]>([]);
  const [modifiedReservations, setModifiedReservations] = useState<ReservationItemData[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [showErrorToast, setShowErrorToast] = useState<boolean>(false);
  const [showFutureErrorToast, setShowFutureErrorToast] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [inputDateValue, setInputDateValue] = useState<any>(DateTime.local().setZone(timeZone).toISODate());

  const { getAccessTokenSilently } = useAuth0();

  const debouncedSetFilteredElements = useCallback(debounce(setFilteredReservations, DEBOUNCE_DURATION), []);

  const emitError = (): void => {
    setShowErrorToast(true);
  };

  const hideToast = () => {
    setShowErrorToast(false);
    setShowSuccessToast(false);
  };

  const clearModifiedReservations = () => {
    setModifiedReservations([]);
  };

  useEffect(() => {
    getAccessTokenSilently().then((authToken) => {
      try {
        getReservationsList(
          ITEMS_PER_CALL,
          page,
          getLocationUUID(),
          timeZone,
          inputDateValue,
          authToken,
          emitError
        ).then((res) => {
          if (res?.length) {
            setReservationsData(res);
            setFilteredReservations(res);
          }
          if (res === null) {
            setReservationsData([]);
            setFilteredReservations([]);
          }
        });
      } catch (e) {
        setShowErrorToast(true);
      }
    });
  }, [page, inputDateValue, getAccessTokenSilently]);

  useEffect(() => {
    debouncedSetFilteredElements(
      reservationsData.filter((reservation) =>
        reservation.user_name.toLowerCase().includes(searchQuery.toLocaleLowerCase())
      )
    );
  }, [debouncedSetFilteredElements, searchQuery, reservationsData]);

  function isFutureDate(currentDate, selectedDate) {
    if (selectedDate) {
      return selectedDate.getTime() > currentDate.getTime();
    }
    return false;
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>): void {
    setSearchQuery(event.target.value);
  }

  function handleCheckboxClick(uuid: string): void {
    if (isFutureDate(now, selectedDate)) {
      setShowFutureErrorToast(true);
      return;
    }
    const updatedFilteredReservations = filteredReservations.map((filteredReservation) =>
      filteredReservation.uuid === uuid
        ? { ...filteredReservation, checked_in: !filteredReservation.checked_in }
        : filteredReservation
    );
    const changedReservations = updatedFilteredReservations.filter((item) => {
      return item.checked_in !== reservationsData.find((apiItem) => apiItem.uuid === item.uuid)?.checked_in;
    });
    setModifiedReservations(changedReservations);
    setFilteredReservations(updatedFilteredReservations);
  }

  const handleDateInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputDateValue(e.currentTarget.value);
    const date = parse(e.currentTarget.value, 'y-MM-dd', new Date());

    if (date) {
      setSelectedDate(date);
    } else {
      setSelectedDate(undefined);
    }
  };

  const handleDaySelect: SelectSingleEventHandler = (date) => {
    setSelectedDate(date);
    if (date) {
      setInputDateValue(format(date, 'y-MM-dd'));
    } else {
      setInputDateValue('');
    }
  };

  const isEmptyState: boolean = !filteredReservations.length;

  return (
    <div className={b()}>
      <div className={b('filters')}>
        <SearchFilter onChange={handleInputChange} />
        <DateSelector
          selected={selectedDate}
          inputValue={inputDateValue}
          handleInputChange={handleDateInputChange}
          handleDaySelect={handleDaySelect}
        />
      </div>
      <div className={b('reservations-count')}>{stringPluralize(filteredReservations.length, 'Reservation')}</div>
      {isEmptyState && !showErrorToast && <ToastMessage type={ToastMessageType.Info} />}
      {showErrorToast && <ToastMessage type={ToastMessageType.Error} autoClose hideToast={hideToast} />}
      {showFutureErrorToast && <ToastMessage type={ToastMessageType.FutureError} autoClose hideToast={hideToast} />}
      {showSuccessToast && <ToastMessage type={ToastMessageType.Success} autoClose hideToast={hideToast} />}
      {!!filteredReservations?.length &&
        !showErrorToast &&
        !showSuccessToast &&
        filteredReservations.map(({ uuid, start, finish, user_name, reservable_type, reservable_name, checked_in }) => {
          return (
            <ReservationItem
              checked={checked_in}
              key={uuid}
              userName={user_name}
              reservableType={reservable_type}
              reservableName={reservable_name}
              start={start}
              finish={finish}
              onClick={() => handleCheckboxClick(uuid)}
            />
          );
        })}
      {!isEmptyState && (
        <ConfirmationModal
          reservations={modifiedReservations}
          showError={setShowErrorToast}
          showSuccess={setShowSuccessToast}
          clearModifiedReservations={clearModifiedReservations}
        />
      )}
      <Pagination
        boundaryRange={0}
        defaultActivePage={1}
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        siblingRange={0}
        totalPages={99}
        onPageChange={(_, props) => {
          if (props?.activePage) {
            setPage(props?.activePage as number);
          }
        }}
      />
    </div>
  );
}
