import { DateTime } from 'luxon';
import { LOCATION_UUID, LOCATION_TIME_ZONE } from './reservation.constants';

export const formatTimeInZone = (dt: string, locale: string, timeZone: string): string => {
  return DateTime.fromISO(dt).setLocale(locale).setZone(timeZone).toLocaleString(DateTime.TIME_SIMPLE);
};

export const formatDateInZone = (dt: string, timeZone: string): string => {
  return DateTime.fromISO(dt).setZone(timeZone).toLocaleString(DateTime.DATE_MED);
};

export const formatStartToEnd = (start: string, end: string, locale: string, zone: string): string => {
  return `${formatTimeInZone(start, locale, zone)} - ${formatTimeInZone(end, locale, zone)}`;
};

export const buildDateWithTimezone = (
  time: string,
  date?: string,
  timeZone?: string,
  options = {},
  utc = false
): DateTime => {
  const dateTime =
    date && timeZone
      ? DateTime.fromISO(`${date}T${time}`, options).setZone(timeZone, { keepLocalTime: true })
      : DateTime.fromISO(time, options);
  if (utc) {
    return dateTime.toUTC();
  }
  return dateTime;
};

export const stringPluralize = (
  count: number,
  str: string,
  suffix: string = 's',
  includeCount: boolean = true
): string => {
  let res = `${str}${count > 1 ? suffix : ''}`;
  if (includeCount === true) {
    return `${count} ${res}`;
  }
  return res;
};

export const getLocationUUID = (): string => LOCATION_UUID;
export const getTimeZone = (): string => LOCATION_TIME_ZONE;
