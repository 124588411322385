import React, { ReactNode } from 'react';
import './spinner-overlay.scss';

interface SpinnerOverlayProps {
  loading: boolean;
  children?: ReactNode;
}

export const SpinnerOverlay = ({ loading, children }: SpinnerOverlayProps) => (
  <div className={loading ? 'spinner-overlay' : ''}>{children}</div>
);
